import { transformData } from '@crehana/utils';

import {
  GetUserEnrollmentsQuery,
  GetUserEnrollmentsQuery_content_enrollments_user_enrollments_edges_node,
} from '../../graphql/types/GetUserEnrollmentsQuery';
import { TUserEnrollment } from './types';

export const parseCourseEnrollment = (
  node: GetUserEnrollmentsQuery_content_enrollments_user_enrollments_edges_node,
): TUserEnrollment => {
  const { course, progress, is_hidden, is_certified, certificate } = node;
  const dashboardUrl = `/clases/v2/${course?.id}`;
  const dashboardDetailUrl = `${dashboardUrl}/detalle/`;
  const dashboardProjectViewUrl = `${dashboardUrl}/proyecto/`;
  const dashboardProjecCreatetUrl = `${dashboardUrl}/proyecto/crear/`;
  const playerUrl = `${dashboardUrl}/player/`;
  const certificateUrl = certificate?.attachment_url || '';

  return {
    ...node,
    certificate,
    progress: progress || 0,
    dashboardDetailUrl,
    dashboardProjectViewUrl,
    dashboardProjecCreatetUrl,
    playerUrl,
    certificateUrl,
    isHidden: is_hidden || false,
    isCertified: is_certified || false,
  };
};

export const parseCourseEnrollmentsQuery = (
  data?: GetUserEnrollmentsQuery,
): TUserEnrollment[] => {
  const dataTransform = transformData(
    data?.content_enrollments.user_enrollments,
  );

  return dataTransform.map(parseCourseEnrollment);
};
